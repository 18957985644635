.Index {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: top;
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  min-height: 10vh;
  font-size: calc(10px + 2vmin);
}

a {
  color: #61dafb;
}

a:hover {
  color: #85d6ec;
}

.Navigation {
  justify-content: center;
  margin-block-start: 0;
  background-color: #474f5f;
  border: 1px solid black;
  width: 100px;
  max-width: 100%;
  margin: auto;
  color: #61dafb;
  padding-inline-start: 0;
  padding: 10px;
}

.Boxed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: top;
  border: 0;
  margin: 0;
  padding : 10px;
  height: 100%;
  background-color: #474f5f;
}

li {
  list-style-type: none; 
}
li::before {
  content: "- ";
}
